<template>
  <b-overlay
    :show="customer.isPending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <component :is="userData === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="userData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching customer data
        </h4>
        <div class="alert-body">
          No customer found with this customer id. Check
          <b-link
            class="alert-link"
            :to="{ name: CUSTOMER_LIST}"
          >
            Customer List
          </b-link>
          for other customers
        </div>
      </b-alert>
      <div v-if="userData">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon
            icon="UserIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Customer Information
          </h4>
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form: Personal Info Form -->
          <b-form
            class="mt-1"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <!-- Field: Name -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <validation-provider
                  name="Name"
                  rules="required"
                >
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label="Name"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="userData.name"
                      :state="errors[0] ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Last name -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Last name"
                  label-for="last-name"
                >
                  <b-form-input
                    id="last-name"
                    v-model="userData.lastName"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Email -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <validation-provider
                  name="email"
                  vid="email"
                  rules="requiredPhoneOrEmail:@phone|email"
                >
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="userData.email"
                      :state="errors[0] ? false : null"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Phone -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <validation-provider
                  rules="requiredPhoneOrEmail:@email|regex:^(\+?[0-9]+)$"
                  name="phone"
                  vid="phone"
                >
                  <b-form-group
                    slot-scope="{ valid, errors }"
                    label="Phone"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="userData.phone"
                      :state="errors[0] ? false : null"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  type="submit"
                >
                  Save Changes
                </b-button>
                <b-button
                  variant="outline-secondary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="$router.go(-1)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </component>
  </b-overlay>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import { ref, onUnmounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { CUSTOMER_LIST } from '@/router/routes/routes-names'
import { useToast } from 'vue-toastification/composition'
import { mapState } from 'vuex'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import customerStoreModule from './StylistCustomerStoreModule'

extend('requiredPhoneOrEmail', {
  params: ['target'],
  validate(value, { target }) {
    return !!(value || target)
  },
  computesRequired: true,
  message: 'Phone or email is required',
})

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BOverlay,
    BFormInput,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BFormCheckboxGroup,
    BButton,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  computed: {
    ...mapState(
      'StylistCustomerStoreModule', {
        customer: state => state.customer,
      },
    ),
  },
  setup() {
    const blankCustomerData = ref({
      id: null,
      name: null,
      lastName: null,
      email: null,
      phone: null,
    })

    const CUSTOMER_APP_STORE_MODULE_NAME = 'customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const userData = ref(JSON.parse(JSON.stringify(blankCustomerData)))
    const resetCustomerData = () => {
      userData.value = JSON.parse(JSON.stringify(blankCustomerData))
    }

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetCustomerData)

    store.dispatch('customer/fetchCustomer', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    const onSubmit = () => {
      store.dispatch('customer/updateCustomer', userData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Customer has been updated',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          router.push({
            name: CUSTOMER_LIST,
          })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Customer has not been updated',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      userData,
      onSubmit,
      refFormObserver,
      resetForm,
      CUSTOMER_LIST,
    }
  },
}
</script>

<style>

</style>
