var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.customer.isPending,"variant":"secondary","spinner-variant":"primary","blur":"0","opacity":".75","rounded":"lg"}},[_c(_vm.userData === undefined ? 'div' : 'b-card',{tag:"component"},[_c('b-alert',{attrs:{"variant":"danger","show":_vm.userData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error fetching customer data ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" No customer found with this customer id. Check "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: _vm.CUSTOMER_LIST}}},[_vm._v(" Customer List ")]),_vm._v(" for other customers ")],1)]),(_vm.userData)?_c('div',[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Customer Information ")])],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Last name","label-for":"last-name"}},[_c('b-form-input',{attrs:{"id":"last-name"},model:{value:(_vm.userData.lastName),callback:function ($$v) {_vm.$set(_vm.userData, "lastName", $$v)},expression:"userData.lastName"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"requiredPhoneOrEmail:@phone|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","state":errors[0] ? false : null,"trim":""},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"rules":"requiredPhoneOrEmail:@email|regex:^(\\+?[0-9]+)$","name":"phone","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","state":errors[0] ? false : null,"trim":""},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" Save Changes ")]),_c('b-button',{attrs:{"variant":"outline-secondary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")])],1)],1)],1)]}}],null,false,1367014247)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }